import { useContext, useState } from "react";
import UserContext from '../shared/UserContext.js';
import FormulaireReponses from "./FormulaireReponses.jsx";

export default function ContentIndices() {
  //Etats
  const { session, setSession } = useContext(UserContext);
  let contenu = [];

  const enigmes = session.route;
  const enigme = session.currentPage;
  const render = (session.currentPage >= 0 && session.step > 1)?true:false;
  const history = session.history;

  //Comportements
  if (!render) return;

  const handleBackToMenu = (index, enigme) => {
    setSession({session, type:"renderMenu"})
  };

  const handleShowHint = () => {
    const hst2 = [...session.history];
    console.log("hst2[currentPage]");
    console.log(hst2);
    if (session.history[session.currentPage] < session.route[session.currentPage].texteIndice.length) {
      hst2[session.currentPage] = hst2[session.currentPage] + 1;
      console.log("hst2[currentPage]");
      console.log(hst2);
    }
    setSession({session, type: 'newClue', value: hst2 });
  }

  if (history == null) {
    console.log("Erreur : history est null");
    return;
  }

  if (enigmes == null) {
    console.log("Erreur : enigmes est null");
    return;
  }

  const affEnigme = () => {
    for (let i = 0; i < history[enigme]; i++) {
      if (i === enigmes[enigme].texteIndice.length - 1)
        contenu.push("La solution :");
      contenu.push( enigmes[enigme].texteIndice[i] );
    }
    return contenu;
  };

  const affLib3 = () => {

    if (typeof enigmes[enigme].lib === 'undefined') {
      return (
        <div>
          <h3 className="text-center">page n°{enigmes[enigme].page}</h3>
        </div>
      );
    } else {
      const urlOrText = enigmes[enigme].lib;
      
      if (urlOrText.length > 25) {

        return (
          <img
            className="img_full"
            src = {urlOrText}
            alt="page"
          />
        );
      } else {
        return (
          <div>
            <h3 className="text-center">page n°{enigmes[enigme].page}</h3>
            <h4 className="text-center">{urlOrText}</h4>
          </div>
        );
      }
    }
  };

  const affLibButtonHint = () => {
    let ret = "";
    let classSupp = "btn btn-primary btn-lg btn-block buttonGetIndice";

    if (history[enigme] === enigmes[enigme].texteIndice.length) {
        ret = "Solution affichée";
        classSupp = "btn btn-primary btn-lg btn-block buttonGetIndice btn-disabled"
    } else if (history[enigme] === enigmes[enigme].texteIndice.length - 1)
      ret = "Afficher la solution";
    else {
      ret =
        "Afficher un indice " +
        history[enigme] +
        "/" +
        (enigmes[enigme].texteIndice.length - 1);
    }

    return (
      <button
        onClick={() => handleShowHint()}
        className={classSupp}
         >
         {ret}
      </button>
    );
  };

  if (enigmes[enigme].page === 99) {
    return (<>
              <FormulaireReponses/>
            </>
    )
  };

  //Affichage
  return (
    <div className="content">
      {affLib3()}
      <button
        onClick={() => handleBackToMenu()}
        className="btn btn-primary btn-lg btn-block"
      >
        Choisir une autre énigme
      </button>
      <div className="contentIndice text-center">
        {affEnigme().map((tab, index) => (
            <h3 key={index}>{tab}</h3>
        ))}
      </div>
      {affLibButtonHint()}
    </div>
  );
}
