/*
      VERSION B DU CARNET
*/

const MUSEEROMA_B = [
  {
    page: 2,
    lib: "Première énigme !",
    texteIndice: ["Rien à ré-écrire pourtant", "S'imaginer déplacer, mais ne touchez à rien", "Retourner (lisez à l'envers)"]
  },
  {
    page: 4,
    lib: "A bien voir...",
    texteIndice: [
      "Chronologie fait sens",
      "Classez les plantes d'une introduction la plus ancienne à la plus récente",
      "Gaulois"
    ]
  },
    {
      page: 5,
      lib: "Liaisons dangereuses",
      texteIndice: [
        "Détail sur l'homme",
        "Inversion",
        "Il est droitier (sa montre est à gauche mais photo inversée)"
      ]
    },
    {
      page: 6,
      lib: "Photo trompeuse",
      texteIndice: [
        "Une information est à retenir, mais pas telle quelle",
        "Le texte affiché est étrange",
        "Le suspect est droitier, éliminez le gaucher (l'image est inversée)"
      ]
    },
    {
      page: 9,
      lib: "Etiquettes",
      texteIndice: [
        "Il faut bien faire la somme des petites pastilles blanches rattachées à chaque oeuvre",
        "Mais l'une d'entre elle ne vas pas",
        "Vingt deux (le dernier 6 devient un 9)"
      ]
    },
    {
      page: 11,
      lib: "Ce n'est pas un jeu",
      texteIndice: [
        "L'auteur du carnet",
        "et l'auteur de la sculpture",
        "MILLE HUIT CENT SOIXANTE SIX (à droite du buste)"
      ]
    },
    {
      page: 13,
      lib: "Lumières",
      texteIndice: [
        "Et non 110 n'est pas la réponse",
        "Les recyclés sont aussi recyclable",
        "Cent onze"]
    },
    {
      page: 16,
      lib: "Vision",
      texteIndice: [
        "Comparez",
        "Il y a des pots en trop",
        "Aube"
      ]
    },
    {
      page: 18,
      lib: "L'As des As",
      texteIndice: [
        "L'As exposé permet juste de voir quoi chercher",
        "Vous avez la réponse en main",
        "Une fois l'indice trouvé, retirez les lettres TOUT",
        "Le suspect n'est PAS Mme Giree"
      ]
    },
    {
      page: 20,
      lib: "Affaire de sens",
      texteIndice: [
        "pas une lettre",
        "L n'est pas une lettre mais un membre",
        "32 (Ailes, car il y a 16 oiseaux dans mosaïque)"
      ]
    },
    {
      page: 22,
      lib: "Balade à la tour Magne",
      texteIndice: [
        "Retrouvez ce Propylée, même abimé !",
        "La réponse est lisible",
        "PUBLICA de diane"
      ]
    },
    {
      page: 24,
      lib: "Monnaies",
      texteIndice: [
        "Trouvez la bonne vitrine des Julio-Claudiens",
        "On vois beaucoups de faces, sauf ?",
        "Caius et Lucius"
      ]
    },
    {
      page: 26,
      lib: "Verres",
      texteIndice: [
        "Trouvez la bonne vitrine",
        "Et trouvez les points communs",
        "Botft (car ANSES)"
      ]
    },
    {
      page: 29,
      lib: "Prise de hauteur",
      texteIndice: [
        "Un problème central",
        "Penchez-vous mais pas au bord",
        "Conservez les Asterix et ..",
        "Romanité"
      ]
    },
    {
      page: 31,
      lib: "Horizon",
      texteIndice: ["Comparez",
        "Il manque quelque chose, venant d'où ?", "Eglise Saint Paul"]
    },
    {
      page: 33,
      lib: "Alibis",
      texteIndice: [
        "Il y a bien un alibi qui contient une erreur factuelle",
        "Le palais de justice n'a que 3 étages, impossible d'être au 5ème",
        "Le wine bar est bien au RDC, l'alibi est crédible",
        "Seul le suspect n°4 est correct, il est à écarter des suspects"
      ]
    },
    {
      page: 37,
      lib: "Sésame",
      texteIndice: [
        "Le sésame pour le musée",
        "Il tient dans la main, permet d'entrer, superposez-le",
        "https://bit.ly/m/2498"
      ]
    },
    {
      page: 99,
      lib_page: "Terminer le parcours",
      lib: "Vous avez terminé le parcours ? Bravo ! Alors où se cache la mosaïque ?",
      lib_win_title: "Bravo ! Félicitations !",
      lib_win_texte: "Votre temps a été enregistré. Retournez voir l'animateur.",
      questions: ["Quelle type de voie ?", "Quel nom de voie ?", "Qui est le coupable du vol ?"],
      answers: [
        ["Impasse", "Square", "Rue", "Allée", "Chemin", "Route", "Musée", "Maison"],
        ["11 novembre", "3 piliers", "Abeilles", "Agrippa", "Aigoual", "Barutel", "Goethe", "Fayard", "Mourrier", "Aucubas", "Carrée"],
        ["Mégane Giree", "Pierre Arancif", "Johan Withmen", "Julien Bondoré"]]
    }
  ];

export default MUSEEROMA_B;